// @ts-nocheck
import $ from 'jquery';
import BaseView from 'base_view';
import ClientSideValidations from 'rails.validations';
import { loadTokenExIframeConfig, until } from '../../../helpers/tokenEx';
import * as Rollbar from '@appfolio/rollbar';

export default class CcAccountInfoView extends BaseView {
  constructor() {
    super();
    ClientSideValidations.callbacks.form.fail = () => {
      const firstValidationError = $('.row.is-invalid')[0];
      firstValidationError && firstValidationError.scrollIntoView();
    };

    let tokenExIframe;
    let isCardNumberBlurred = false;
    let cardErrorMessage;

    let isCvvBlurred = false;
    let cvvErrorMessage;

    const expirationTime = 15 * 60 * 1_000; // iframe will expire after 15 minutes

    const loadTokenExIframe = () => {
      loadTokenExIframeConfig()
        .then((backendTokenExConfig) => {
          const tokenExScript = document.createElement('script');
          tokenExScript.id = 'tokenExScript';
          tokenExScript.src = backendTokenExConfig.iframeUrl;
          tokenExScript.integrity = backendTokenExConfig.iframeIntegrity;
          tokenExScript.crossOrigin = 'anonymous';

          tokenExScript.onload = () => {
            const iframeConfig = {
              origin: backendTokenExConfig.origin,
              timestamp: backendTokenExConfig.timestamp,
              tokenExID: backendTokenExConfig.tokenExID,
              tokenScheme: backendTokenExConfig.tokenScheme,
              authenticationKey: backendTokenExConfig.authenticationKey,
              pci: true,
              enableValidateOnBlur: true,
              cvv: true,
              cvvContainerID: 'tokenExCvv',

              // styling
              styles: {
                base: `
                position: absolute;
                display:block;
                width:100%;
                height: 2.125rem;
                flex-grow: 1;
                box-sizing: border-box;
                border: 1px solid #c2c6cb;
                margin: 0;
                padding: 0.3125em 0.5em 0.3125em;
                font-size: 14px;
                border-radius: 0.175rem;
                box-shadow: 0 1px 3px rgba(0,0,0,0.07) inset;`,
                focus: 'z-index: 5;',
                error: 'box-sizing: border-box; border: 1px solid #d32c12;',
                cvv: {
                  base: `
                  position: absolute;
                  display:block;
                  width:100%;
                  height: 100%;
                  max-width: 100%;
                  max-height: 100%;
                  flex-grow: 1;
                  box-sizing: border-box;
                  border: 1px solid #c2c6cb;
                  margin: 0;
                  padding: 0.3125em 0.5em 0.3125em;
                  font-size: 14px;
                  border-radius: 0.175rem;
                  box-shadow: 0 1px 3px rgba(0,0,0,0.07) inset;`,
                  focus: 'z-index: 5;',
                  error: 'box-sizing: border-box; border: 1px solid #d32c12;',
                },
              },

              // optional configs
              enablePrettyFormat: true,
              cardMaxLengths: {
                americanExpress: 15,
                visa: 16,
                mastercard: 16,
                discover: 16,
                diners: 16,
                jcb: 16,
              },
              enableAutoComplete: true,
            };

            if (window.TokenEx?.Iframe) {
              tokenExIframe = new window.TokenEx.Iframe('tokenExIframeDiv', iframeConfig);

              tokenExIframe.on('blur', () => {
                isCardNumberBlurred = true;
                displayCardNumberErrorMessage();
              });
              tokenExIframe.on('cvvBlur', () => {
                isCvvBlurred = true;
                displayCvvErrorMessage();
              });

              tokenExIframe.on('cardTypeChange', (data) => {
                const cardType = data.possibleCardType.toLowerCase();
                if (cardType !== 'unknown') {
                  $('#payment_token_card_type').get(0).value = cardType;
                  $('#cc-icon-list img').addClass('cc-icon--faded');
                  $('#cc-icon-' + cardType).removeClass('cc-icon--faded');
                } else {
                  clearCardTypes();
                }
              });

              tokenExIframe.on('error', async (e) => {
                document.getElementById('tokenexErrorBanner').style.display = '';
                const cardSubmit = $('#card-submit')[0];
                cardSubmit.disabled = false;
                cardSubmit.value = 'Continue';

                const referenceNumber = e.referenceNumber;
                if (!window.sessionStorage[referenceNumber]) {
                  Rollbar.error(
                    `[tokenEx Iframe] - Encountered an error ${e.error}\nReference Number: ${referenceNumber}`,
                  );
                  window.sessionStorage[referenceNumber] = true;
                }
              });

              tokenExIframe.on('tokenize', (tokenResp) => {
                $('#payment_token_bin').get(0).value = tokenResp.firstSix;
                $('#payment_token_card_last4').get(0).value = tokenResp.lastFour;
                $('#payment_token_card_number_token').get(0).value = tokenResp.token;
                $('#payment_token_card_token_hmac').get(0).value = tokenResp.tokenHMAC;
              });

              tokenExIframe.on('validate', (validationResp) => {
                if (validationResp.cardType !== undefined) {
                  const cardType = validationResp.cardType.toLowerCase();
                  $('#payment_token_card_type').get(0).value = cardType;
                  $('#cc-icon-list img').addClass('cc-icon--faded');
                  $('#cc-icon-' + cardType).removeClass('cc-icon--faded');
                  cardErrorMessage = '';

                  if (validationResp.cardType.toLowerCase() === 'americanexpress') {
                    cardErrorMessage = 'enter a valid card number';
                    const iframeDiv = $('#tokenExIframeDiv');

                    iframeDiv.css('outline', 'solid 1px #f00');
                    iframeDiv.addClass('rounded');
                  } else {
                    cardErrorMessage = '';
                    const iframeDiv = $('#tokenExIframeDiv');
                    iframeDiv.css('outline', '');
                    iframeDiv.removeClass('rounded');
                  }
                } else if (validationResp.validator === 'format') {
                  cardErrorMessage = 'enter a valid card number';
                } else if (validationResp.validator === 'required') {
                  cardErrorMessage = "can't be blank";
                }
                displayCardNumberErrorMessage();

                if (validationResp.cvvValidator === 'format') {
                  cvvErrorMessage = 'enter a valid security code';
                } else if (validationResp.cvvValidator === 'required') {
                  cvvErrorMessage = "can't be blank";
                } else {
                  cvvErrorMessage = '';
                }
                displayCvvErrorMessage();
              });

              tokenExIframe.load();
            }
          };

          tokenExScript.onerror = () => {
            document.getElementById('tokenexErrorBanner').style.display = '';
            $('#new_payment_token').get(0).style.display = 'none';
            Rollbar.error('[tokenEx Iframe] - load failure');
          };

          document.head.appendChild(tokenExScript);

          setTimeout(() => {
            clearIframeDivs();
            clearValidationMessages();
            clearCardTypes();
            displayTimeoutMessage();
            loadTokenExIframe();
          }, expirationTime);
        })
        .catch((err) => {
          document.getElementById('tokenexErrorBanner').style.display = '';
          $('#new_payment_token').get(0).style.display = 'none';
          Rollbar.error(`[tokenEx Iframe] - load failure ${JSON.stringify(err, Object.getOwnPropertyNames(err))}`);
        });
    };

    // load iframe and set configs
    loadTokenExIframe();

    // override non-tokenex submit handler
    document.getElementById('card-submit').addEventListener('click', async () => {
      const iframesValid = cvvErrorMessage === '' && cardErrorMessage === '';

      displayValidations();
      if (checkNoInvalidForms() && iframesValid) {
        const cardSubmit = document.getElementById('card-submit');
        cardSubmit.disabled = true;
        cardSubmit.value = 'Please Wait...';
        tokenExIframe?.tokenize();
        await until((_) => {
          return (
            $('#payment_token_bin').get(0).value &&
            $('#payment_token_card_last4').get(0).value &&
            $('#payment_token_card_number_token').get(0).value &&
            $('#payment_token_card_token_hmac').get(0).value
          );
        });
        document.forms[0].submit();
      }
    });

    const checkNoInvalidForms = () =>
      Array.from(document.querySelectorAll('input, select')).reduce(
        (acc, curr) => acc & !curr.className.toLowerCase().includes('invalid'),
        true,
      );

    const displayValidations = () => {
      Array.from(document.querySelectorAll('input, select')).forEach((el) => {
        el.focus();
        el.blur();
      });

      isCardNumberBlurred = isCvvBlurred = true;
      tokenExIframe?.validate();
    };

    const clearCardTypes = () => {
      $('#payment_token_card_type').get(0).value = '';
      for (const icon of $('.cc-icon')) {
        $(icon).removeClass('cc-icon--faded');
      }
    };

    const clearIframeDivs = () => {
      document.getElementById('tokenExIframeDiv').innerHTML = '';
      document.getElementById('tokenExCvv').innerHTML = '';
    };

    const clearValidationMessages = () => {
      cardErrorMessage = cvvErrorMessage = undefined;
      isCardNumberBlurred = isCvvBlurred = false;

      displayCardNumberErrorMessage();
      displayCvvErrorMessage();

      $('div#cc-number').removeClass('is-invalid');
      $('div#cvv-number').removeClass('is-invalid');
    };

    const displayCardNumberErrorMessage = () => {
      if (isCardNumberBlurred && String(cardErrorMessage).length > 0) {
        document.getElementById('invalidCardNumber').textContent = cardErrorMessage;
        document.getElementById('invalidCardNumber').style.display = '';
        $('div#cc-number').addClass('is-invalid');
      } else {
        document.getElementById('invalidCardNumber').style.display = 'none';
        $('div#cc-number').removeClass('is-invalid');
      }
    };

    const displayCvvErrorMessage = () => {
      if (isCvvBlurred && String(cvvErrorMessage).length > 0) {
        document.getElementById('invalidCvv').textContent = cvvErrorMessage;
        document.getElementById('invalidCvv').style.display = '';
        $('div#cvv-number').addClass('is-invalid');
      } else {
        document.getElementById('invalidCvv').style.display = 'none';
        $('div#cvv-number').removeClass('is-invalid');
      }
    };

    const displayTimeoutMessage = () => {
      document.getElementById('tokenexRefreshBanner').style.display = '';
    };

    this.markPageLoaded();
  }
}
